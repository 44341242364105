/*
===============================================================================
Styles for login popup form
===============================================================================
*/

.login-window {
    min-height: 350px;
}

.ui-login {
    margin: 0;
    position: relative;
    overflow: hidden;
}

.burger-login {
    margin: 0;
    position: relative;
    overflow: hidden;
    left: inherit;
}

.ui-login .ui-form {
    margin: 0;
}

.ui-login .header {
    margin: 20px 0;
    padding: 0;
    font-size: var(--font-size-body1);
    line-height: 1.57;
    overflow: hidden;
}

.ui-login .header-separator {
    position: relative;
    display: inline-block;
}

    .ui-login .header-separator:before,
    .ui-login .header-separator:after {
        content: "";
        position: absolute;
        top: 50%;
        width: 1000px;
        height: 1px;
        z-index: -1;
        background: var(--grey-300);
    }

    .ui-login .header-separator:before {
        margin-right: 20px;
        right: 100%;
    }

    .ui-login .header-separator:after {
        margin-left: 20px;
        left: 100%;
    }

.ui-login .description-text,
.w-login-half .description-text {
    margin-bottom: 15px;
}

.ui-login .back-to-login-link {
    cursor: pointer;
    font-size: var(--font-size-subtitle1);
    padding: .2em .7em;
    position: absolute;
    top: -1px;
    z-index: 10;
}

.ui-burger-navigation .ui-login-form .back-to-login-link::before {
    margin-right: 0;
}

.ui-login .w-buttons {
    margin-bottom: 10px;
}

.ui-login .social-login {
    font-size: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.ui-login .social-login-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    margin: 0 5px;
    text-align: center;
}

.ui-login .social-login-link {
    display: inline-block;
    cursor: pointer;
    padding: 0 5px;
    /*Hide the icon text*/
    font-size: 0;
    color: transparent;
}

.ui-login .social-login-link::before {
    font-size: 7.4rem;
    line-height: 1;
    transition: transform .15s;
}

.ui-login .social-login-link:hover::before,
.ui-login .social-login-link:focus::before {
    transform: scale(1.01);
}

.ui-login .heading,
.ui-popup-login-form .heading {
    text-align: center;
    text-transform: uppercase;
    margin: 0 30px 20px;
    border: 0;
}

.ui-login .w-input-list-item {
    list-style-type: none;
}

/* #region tabs */
.burger-login .heading {
    font-size: var(--font-size-h6);
    line-height: 1.41;
}

.ui-burger-navigation .ui-login {
    margin: 20px;
}

.forgot-password-iframe {
    padding: 30px 20px 20px;
    width: calc(100% - 40px);
}

.vhide-label {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
    margin: -1px;
    padding: 0;
}
/* #endregion tabs */

/* #region shopping bag checkout */
.w-login-half {
    box-sizing: border-box;
    width: 50%;
    display: block;
    float: left;
}
.w-login-half.first {
    padding-right: 35px;
    overflow: hidden;
}
.w-login-half .ui-form {
    margin: 0;
}

.w-login-half .ui-login {
    border-left: 1px solid var(--grey-300);
    padding-left: 35px;
    padding-bottom: 10px;
}

.w-login-half .heading {
    font-size: var(--font-size-h6);
    line-height: 1.41;
}

/* BP 1 & 2 */
@media only screen and (max-width: 767px) {
    .w-login-half {
        width: 100%;
        margin-right: 0;
        float: none;
        position: relative;
        padding-bottom: 50px;
        min-height: 300px;
        padding-right: 0;
    }

    .w-login-half.first {
        padding-right: 0;
    }

    .w-login-half .ui-login {
        border-left: 0;
        padding-left: 0;
    }

    .w-login-half.first .ui-popup-login-form {
        width: 100%;
    }

    .bottom-area {
        display: block;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center;
        padding: 15px 0 0;
    }

    .toggle-link {
        font-weight: bold;
    }

    .toggle-link:hover {
        color: var(--text-hover);
    }
}

/* BP 3, 4, 5 */
@media only screen and (min-width: 768px) {
    .bottom-area {
        display: none;
    }
}
/* #endregion shopping bag checkout */